import React from "react";
import ReactDOM from "react-dom";
import App from "Components/App";
import "./api";
import { datadogRum, datadogLogs } from "@datadog/browser-rum";

// const today = new Date().getDay();
// let isMondayTuesday = false;
// let isTuesday = false;

// if (today === 1) {
//   isMondayTuesday = true;
// } else if (today === 2) {
//   isMondayTuesday = true;
// }
// let conditionalSampleRate = isMondayTuesday ? 100 : 0;

// datadogLogs.init({
//   clientToken: "pub29804c1fe7159ab37829b42586da649a",
//   site: "datadoghq.com",
//   forwardErrorsToLogs: true,
//   sessionSampleRate: 100,
//   service: "joongomarket",
//   env: "prod",
//   forwardConsoleLogs: "all",
// });

datadogRum.init({
  applicationId: "4bb98f71-b196-4541-961d-98d31dfd7afa",
  clientToken: "pubcb01a275c96ddc9db9c4d47a87327177",
  site: "datadoghq.com",
  service: "joongomarket",
  env: "prod",
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow",
  // allowedTracingUrls: ["https://d2bm0fcipvy2b6.cloudfront.net/"],
});

// console.log("Hello RUM Log");
// datadogLogs.logger.info("Button clicked", { name: "buttonName", id: 123 });
// datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById("root")
);
