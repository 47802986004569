import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const JoongoListContainer = styled.div`
  background: #fafbf2;
  cursor: pointer;
  display: flex;
  flex-basis: 50%;
  flex-direction: row;
  border-bottom: 0.1rem solid;
  border-color: #d7d2ce;
  align-items: center;
  @media only screen and (max-width: 750px) {
    width: 100%;
    flex-basis: 50%;
  }
  @media only screen and (min-width: 780px) {
    width: 100%;
    flex-basis: 45%;
  }
`;
const JoongoThumbnail = styled.img`
  height: auto;
  width: 125px;
  margin: 30px 0 30px 0;
  object-fit: contain;
  @media only screen and (max-width: 750px) {
    height: auto;
    width: 130px;
    margin: 5% 1% 3% 0;
    object-fit: contain;
  }
`;

const JoongoDetail = styled.div`
  width: 100%;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1% 0 2% 3%;
  @media only screen and (max-width: 750px) {
    margin: 1% 0 2% 0;
  }
`;

const JoongoHeader = styled.div`
  color: #5d5959;
  font-size: 19px;
  @media only screen and (max-width: 750px) {
    font-size: 13px;
  }
`;
const JoongoTitle = styled.div`
  padding-bottom: 0.5%;
  font-size: 19px;
  @media only screen and (max-width: 750px) {
    padding-bottom: 0.5%;
    font-size: 14px;
  }
`;
const JoongoPrice = styled.div`
  font-size: 19px;
  padding-bottom: 1.5%;
  font-weight: bold;
  text-decoration: underline;
  color: #ae0000;
  @media only screen and (max-width: 750px) {
    padding-bottom: 0.5%;
    font-size: 15px;
  }
`;

const JoongoPromise = styled.div`
  font-size: 19px;
  color: #6e51c8;
  @media only screen and (max-width: 750px) {
    padding-bottom: 0.2%;
    font-size: 14px;
  }
`;

const JoongoRegion = styled.div`
  font-size: 19px;
  padding-bottom: 0.2%;
  @media only screen and (max-width: 750px) {
    font-size: 14px;
  }
`;

const JoongoWriteTime = styled.div`
  font-size: 19px;
  padding-bottom: 0.2%;
  @media only screen and (max-width: 750px) {
    font-size: 14px;
  }
`;

const JoongoCondition = styled.div`
  font-size: 19px;
  padding-bottom: 0.2%;
  color: #00891a;
  @media only screen and (max-width: 750px) {
    font-size: 14px;
  }
`;

const Joongo = ({
  type,
  mobileLink,
  pcLink,
  header,
  imgSrc,
  title,
  price,
  deliveryType,
  condition,
  region,
  writeTime,
}) => {
  function redirect() {
    isMobile
      ? window.open(mobileLink, "_blank ")
      : window.open(pcLink, "_blank ");
  }
  if (!mobileLink && !pcLink) {
    return <></>;
  } else {
    if (region.length == 0) {
      region = "미기재";
    }
    return (
      <JoongoListContainer onClick={redirect}>
        <JoongoThumbnail src={imgSrc}></JoongoThumbnail>
        <JoongoDetail>
          <JoongoHeader>{type}</JoongoHeader>
          <JoongoHeader>{header}</JoongoHeader>
          <JoongoTitle>{title}</JoongoTitle>
          <JoongoPrice>
            {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
          </JoongoPrice>
          <JoongoCondition>상태: {condition}</JoongoCondition>
          <JoongoPromise>거래 방식: {deliveryType}</JoongoPromise>
          <JoongoRegion>지역: {region}</JoongoRegion>
          <JoongoWriteTime>작성일: {writeTime}</JoongoWriteTime>
        </JoongoDetail>
      </JoongoListContainer>
    );
  }
};

export default Joongo;
