import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "Components/Main";
import { ToastContainer, toast } from "react-toastify";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Main}></Route>
        </Switch>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </>
  );
};

export default App;
