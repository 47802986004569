import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CoupangApi } from "api";

const SearchKeyword = styled.div`
  flex: 4;
  /* height: 48px; */
  /* @media only screen and (max-width: 750px) {
    flex: 4;
    height: 32px;
    padding-bottom: 5px;
  } */
`;

const SearchKeywordFunction = (keyword) => {
  const [keywordList, setKeywordList] = useState([]);
  async function serachKeyword() {
    try {
      const {
        data: { body: keywordList },
      } = await CoupangApi.searchKeyword(keyword);
      if (typeof keywordList === "undefined") {
        setKeywordList([]);
      } else {
        setKeywordList(keywordList);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    serachKeyword();
  }, [keyword]);
  // console.log(keywordList);
  return { keywordList };
};

const KeywordList = ({ initKeyword }) => {
  const [keyword, setKeyword] = useState("");
  const handleKeywordChange = (keyword) => {
    // const searchUrl = `./?keyword=${keyword}/dfaf`;
    // window.location.href = searchUrl;
  };
  const { keywordList } = SearchKeywordFunction(
    Object.values(keyword).join("")
  );
  if (initKeyword) {
    return (
      <SearchKeyword>
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          name="keyword"
          options={keywordList.map((option) => option.keyword)}
          style={{ paddingRight: 15 }}
          onChange={(event, keyword) => handleKeywordChange(keyword)}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(event, keyword) =>
                setKeyword({ ...params.inputProps.value })
              }
              label={initKeyword}
              margin="normal"
              type="text"
              name="keyword"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: "text" }}
              style={{}}
            />
          )}
        />
      </SearchKeyword>
    );
  } else {
    return (
      <SearchKeyword>
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          name="keyword"
          options={keywordList.map((option) => option.keyword)}
          style={{ paddingRight: 15 }}
          onChange={(event, keyword) => handleKeywordChange(keyword)}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(event, keyword) =>
                setKeyword({ ...params.inputProps.value })
              }
              label={"🔍 상품 검색"}
              margin="normal"
              type="text"
              name="keyword"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: "text" }}
              style={{}}
            />
          )}
        />
      </SearchKeyword>
    );
  }
};

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
];

export default KeywordList;
