import React from "react";
import styled from "styled-components";

const ProductListContainer = styled.div`
  /* width: 88%; */
  cursor: pointer;
  display: flex;
  flex-basis: 50%;
  flex-direction: row;
  border-bottom: 0.1rem solid;
  border-color: #d7d2ce;
  align-items: center;
  @media only screen and (max-width: 750px) {
    width: 100%;
    flex-basis: 50%;
  }
  @media only screen and (min-width: 780px) {
    width: 100%;
    flex-basis: 45%;
  }
`;
const ProductThumbnail = styled.img`
  height: 125px;
  @media only screen and (max-width: 750px) {
    height: 130px;
    margin: 5% 1% 3% 0;
  }
`;
const ProductDetail = styled.div`
  width: 100%;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1% 0 2% 3%;
  @media only screen and (max-width: 750px) {
    margin: 1% 0 2% 0;
  }
`;

const ProductHeader = styled.div`
  color: #5d5959;
  font-size: 19px;
  @media only screen and (max-width: 750px) {
    font-size: 13px;
  }
`;
const ProductTitle = styled.div`
  padding-bottom: 0.5%;
  font-size: 19px;
  @media only screen and (max-width: 750px) {
    padding-bottom: 0.5%;
    font-size: 14px;
  }
`;
const ProductPrice = styled.div`
  font-size: 21px;
  padding-bottom: 1.5%;
  font-weight: bold;
  text-decoration: underline;
  color: #ae0000;
  @media only screen and (max-width: 750px) {
    padding-bottom: 0.5%;
    font-size: 15px;
  }
`;

const ProductPromise = styled.div`
  font-size: 19px;
  color: #6e51c8;
  @media only screen and (max-width: 750px) {
    padding-bottom: 0.2%;
    font-size: 14px;
  }
`;

const ProductRating = styled.div`
  font-size: 19px;
  font-weight: bold;
  padding-bottom: 0.2%;
  @media only screen and (max-width: 750px) {
    font-size: 14px;
  }
`;

const ProductRatingCount = styled.div`
  font-size: 19px;
  color: #00891a;
  @media only screen and (max-width: 765px) {
    font-size: 14px;
  }
`;

const CheckStar = (rating) => {
  let star = "";
  if (rating == 3.0) {
    star = "⭐⭐⭐  ";
  } else if (rating == "3.5") {
    star = "⭐⭐⭐🌟  ";
  } else if (rating == "4.0") {
    star = "⭐⭐⭐⭐  ";
  } else if (rating == "4.5") {
    star = "⭐⭐⭐⭐🌟  ";
  } else if (rating == "5.0") {
    star = "⭐⭐⭐⭐⭐  ";
  }
  return star;
};

const Product = ({
  type,
  linkUrl,
  header,
  imgSrc,
  title,
  price,
  promise,
  rating,
  ratingCount,
}) => {
  if (promise) {
    promise = promise + "🚀";
  }
  if (!header) {
    header = "ㅤ";
  } else {
    header = "[" + header + "]";
  }
  function redirect() {
    const moneyUrl = linkUrl + "&lptag=AF9130333&subid=joongomarket";
    window.open(moneyUrl, "_blank");
  }
  const star = CheckStar(rating);
  if (!linkUrl) {
    return <></>;
  } else {
    if (header.length != 1) {
      return (
        <ProductListContainer onClick={redirect}>
          <ProductThumbnail src={imgSrc}></ProductThumbnail>
          <ProductDetail>
            <ProductHeader>{type}</ProductHeader>
            <ProductHeader>{header}</ProductHeader>
            <ProductTitle>{title}</ProductTitle>
            <ProductPrice>
              {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
            </ProductPrice>
            <ProductPromise>{promise}</ProductPromise>
            <ProductRating>{star}</ProductRating>
            <ProductRatingCount>상품 리뷰: {ratingCount} 개</ProductRatingCount>
          </ProductDetail>
        </ProductListContainer>
      );
    } else {
      return (
        <ProductListContainer onClick={redirect}>
          <ProductThumbnail src={imgSrc}></ProductThumbnail>
          <ProductDetail>
            <ProductHeader>{type}</ProductHeader>
            <ProductTitle>{title}</ProductTitle>
            <ProductPrice>
              {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
            </ProductPrice>
            <ProductPromise>{promise}</ProductPromise>
            <ProductRating>{star}</ProductRating>
            <ProductRatingCount>상품 리뷰: {ratingCount} 개</ProductRatingCount>
          </ProductDetail>
        </ProductListContainer>
      );
    }
  }
};

export default Product;
